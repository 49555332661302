











import { reqArticleList } from "@/api";
import { NewsColumns } from "@/types/enum";
import { transformNewsString } from "@/utils";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "Guide"
})
export default class extends Vue {
  content: any[] = [];

  async initList() {
    const { data: list } = await reqArticleList(
      NewsColumns.INSTITUTIONALPROFILE
    );
    if (list && list.length > 0) {
      this.content = transformNewsString(list);
    }
  }

  created() {
    this.initList();
  }
}
