












import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "breadcrumbs"
})
export default class extends Vue {
  get current() {
    return this.$route.query.column || this.$route.name;
  }
}
