import Vue from "vue";
import App from "./App.vue";
import router from "./index";
import store from "../../store";
import "../../plugins/element";

Vue.config.productionTip = false;
// if (window.innerWidth < 750) window.location.href = "/wap";
// 移动端打开pc端的路由(转换)
let List = [
  { web: "/pensionfraud", wap: "/wap/pensionfraud?id=1810140" }
]
for (let i = 0; i < List.length; i++) {
  if (window.innerWidth < 750) {
    if (window.location.href.indexOf(List[i].web) == -1) {
      window.location.href = "/wap";
    } else {
      window.location.href = List[i].wap;
    }
  }
}
new Vue({
  router,
  store,


  render: h => h(App)
}).$mount("#app");
