import { NewsAction, NewsColumns } from "@/types/enum";
import Index from "@/views/web/page/Index.vue";
import Introduction from "@/views/web/page/Introduction.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "首页",
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true
    },
    component: Index
  },
  {
    path: "/register",
    name: "用户注册",
    component: () => import("@/views/web/page/user/Register.vue")
  },
  {
    path: "/login",
    name: "用户登录",
    component: () => import("@/views/web/page/user/Login.vue")
  },
  {
    path: "/forget-password/:name",
    name: "忘记密码",
    meta: {
      withoutBreadCrumbs: true
    },
    component: () => import("@/views/web/page/user/Forget.vue")
  },
  {
    path: "/change-user-info",
    name: "修改信息",
    component: () => import("@/views/web/page/user/ChangeUserInfo.vue")
  },
  {
    path: "/ruin-account",
    name: "注销账号",
    component: () => import("@/views/web/page/user/Ruin.vue")
  },
  {
    path: "/instructions/:harmId",
    name: "举报须知",
    props: true,
    component: () => import("@/views/web/page/Instructions.vue")
  },
  {
    path: "/other",
    name: "其他类",
    props: true,
    component: () => import("@/views/web/page/other.vue")
  },
  {
    path: "/report/register/:harmId",
    name: "注册举报",
    props: true,
    component: () => import("@/views/web/reporting/Register.vue")
  },
  {
    path: "/report/real-name/:harmId",
    name: "实名举报",
    props: true,
    component: () => import("@/views/web/reporting/RealName.vue")
  },
  {
    path: "/report/anonymous/:harmId",
    name: "匿名举报",
    props: true,
    component: () => import("@/views/web/reporting/Anonymous.vue")
  },
  {
    path: "/report/result",
    name: "举报结果",
    component: () => import("@/views/web/reporting/Result.vue")
  },
  {
    path: "/channels",
    name: "各地网信部门举报电话",
    component: () => import("@/views/web/page/Channels.vue")
  },
  {
    path: "/guide",
    name: "举报指南",
    component: () => import("@/views/web/page/Guide.vue")
  },
  {
    path: "/query",
    name: "举报查询",
    component: () => import("@/views/web/page/Query.vue")
  },
  {
    path: "/news/detail",
    name: "信息详情",
    component: () => import("@/views/web/page/Detail.vue")
  },
  {
    path: "/news/information",
    alias: "/information",
    name: "网信信息",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.INFORMATION,
      column: NewsColumns.INFORMATION
    }
  },
  {
    path: "/news/standard",
    alias: "/standard",
    name: "法律",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.STANDARD,
      column: NewsColumns.LAW,
      sign: "standard"
    }
  },
  {
    path: "/news/administer",
    alias: "/administer",
    name: "行政法规",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.STANDARD,
      column: NewsColumns.ADMINISTRATIVEREGULATIONS,
      sign: "standard"
    }
  },
  {
    path: "/news/department",
    alias: "/department",
    name: "部门规章",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.STANDARD,
      column: NewsColumns.DEPARTMENTALREGULATIONS,
      sign: "standard"
    }
  },
  {
    path: "/news/judicial",
    alias: "/judicial",
    name: "司法解释",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.STANDARD,
      column: NewsColumns.JUDICIALINTERPRETATION,
      sign: "standard"
    }
  },
  {
    path: "/news/normative",
    alias: "/normative",
    name: "规范性文件",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.STANDARD,
      column: NewsColumns.NORMATIVEDOCUMENTS,
      sign: "standard"
    }
  },
  {
    path: "/news/policy",
    alias: "/policy",
    name: "政策文件",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.STANDARD,
      column: NewsColumns.POLICYDOCUMENT,
      sign: "standard"
    }
  },
  {
    path: "/news/rumours",
    alias: "/rumours",
    name: "辟谣专区",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.RUMOURS,
      column: NewsColumns.RUMOURS,
      sign: "rumours"
    }
  },
  {
    path: "/news/unionRumours",
    alias: "/unionRumours",
    name: "川渝联合辟谣",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.RUMOURS,
      column: NewsColumns.CYRUMOURS,
      sign: "rumours"
    }
  },
  {
    path: "/news/announcements",
    alias: "/announcements",
    name: "通知公告",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.ANNOUNCEMENTS,
      column: NewsColumns.ANNOUNCEMENTS
    }
  },
  {
    path: "/news/warning",
    alias: "/warning",
    name: "安全警示",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.WARNING,
      column: NewsColumns.WARNING
    }
  },
  {
    path: "/news/claim",
    alias: "/claim",
    name: "网信说法",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.CLAIM,
      column: NewsColumns.CLAIM
    }
  },
  {
    path: "/news/report",
    alias: "/report",
    name: "通知公告",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.REPORTACCEPT,
      column: NewsColumns.REPORTACCEPT
    }
  },
  {
    path: "/news/control",
    alias: "/control",
    name: "综合治理",
    component: () => import("@/views/web/page/News.vue"),
    meta: {
      action: NewsAction.COMPREHENSIVECONTROL,
      column: NewsColumns.COMPREHENSIVECONTROL
    }
  },
  {
    path: "/putting",
    name: "扫黑除恶",
    component: () => import("@/views/web/page/Putting.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/anticheat",
    name: "全民反诈",
    component: () => import("@/views/web/page/Anticheat.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/cqrumours",
    name: "重庆辟谣专题",
    component: () => import("@/views/web/page/CQrumours.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/pensionfraud",
    name: "打击整治养老诈骗专项行动",
    component: () => import("@/views/web/page/PensionFraud.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/cyrumours",
    name: "川渝辟谣专题",
    component: () => import("@/views/web/page/CYrumours.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/history",
    name: "涉历史虚无主义",
    component: () => import("@/views/web/page/history.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/baAndYu",
    name: "清朗巴渝系列专项举报",
    component: () => import("@/views/web/page/BaAndYu.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/wemedia",
    name: "自媒体专项整治",
    component: () => import("@/views/web/page/WeMedia.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/personal-infor",
    name: "互联网平台违法违规收集个人信息举报",
    component: () => import("@/views/web/page/PersonalInfor.vue"),
    meta: {
      withoutContainer: true,
      withoutBreadCrumbs: true,
      headHide: true
    }
  },
  {
    path: "/introduction",
    name: "机构简介",
    component: Introduction
  },
  {
    path: "/report/network-informer-sign",
    name: "志愿者报名",
    props: true,
    component: () => import("@/views/web/reporting/NetworkInformerSign.vue")
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  }
});

export default router;
