














































































































































































































































































































































































































































































































import { reqArticleList } from "@/api";
import { NewsColumns } from "@/types/enum";
import { readNewsDetailHandler, transformNewsString } from "@/utils";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "Index"
})
export default class extends Vue {
  wxxxList: any[] = [];
  tzggList: any[] = [];
  fvfgList: any[] = [];
  // jbslList: any[] = [];
  pyzqList: any[] = [];
  wxsfList: any[] = [];
  // shceList: any[] = [];
  // qmfzList: any[] = [];

  async initList() {
    const { data: list0 } = await reqArticleList(NewsColumns.LAW);
    const { data: list1 } = await reqArticleList(NewsColumns.INFORMATION);
    const { data: list2 } = await reqArticleList(NewsColumns.ANNOUNCEMENTS);
    // const { data: list3 } = await reqArticleList(NewsColumns.REPORTACCEPT);
    const { data: list4 } = await reqArticleList(NewsColumns.RUMOURS);
    const { data: list5 } = await reqArticleList(NewsColumns.CLAIM);
    // const { data: list6 } = await reqArticleList(NewsColumns.ANTITRIADPUTTING);
    // const { data: list7 } = await reqArticleList(
    //   NewsColumns.COMPREHENSIVEANTICHEAT
    // );

    if (list0 && list0.length > 0) {
      this.fvfgList = transformNewsString(list0);
    }
    if (list1 && list1.length > 0) {
      this.wxxxList = transformNewsString(list1);
    }
    if (list2 && list2.length > 0) {
      this.tzggList = transformNewsString(list2);
    }
    // if (list3 && list3.length > 0) {
    //   this.jbslList = transformNewsString(list3);
    // }
    if (list4 && list4.length > 0) {
      this.pyzqList = transformNewsString(list4);
    }

    this.wxsfList = transformNewsString(list5);
    // this.shceList = transformNewsString(list6);
    // this.qmfzList = transformNewsString(list7);
  }

  open(href: string) {
    const loction = window.location.origin;
    window.open(loction + href, "_blank");
  }

  read = readNewsDetailHandler;

  created() {
    this.initList();
  }
  tolink(href: string) {
    window.location.href = href;
  }
}
