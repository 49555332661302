









import { Vue, Component } from "vue-property-decorator";
import Breadcrumbs from "./Breadcrumbs.vue";

@Component({
  components: { Breadcrumbs },
  name: "layouts"
})
export default class extends Vue {
  get withoutBreadCrumbs() {
    return !!this.$route.meta.withoutBreadCrumbs;
  }

  get withoutContainer() {
    return !!this.$route.meta.withoutContainer;
  }
}
