
































import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "app-header"
})
export default class extends Vue {}
