








import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
import Layouts from "./components/Layouts.vue";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  name: "app",
  components: {
    AppHeader,
    AppFooter,
    Layouts
  }
})
export default class extends Vue {
  @Action
  initHarmTypes!: () => void;

  created() {
    this.initHarmTypes();
  }
}
